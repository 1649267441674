<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部添加优惠券一行 -->
<!--      <div class="head">
        <div class="left">
          <b class="left_tit">添加优惠券</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >返回</el-button
          >
        </div>
      </div> -->
      <!-- 添加优惠券文字一行 -->
 <!--     <div class="screen">
        <div class="screen_left">
          <i class="el-icon-search"></i>
          添加优惠券
        </div>
      </div> -->

      <!-- 添加时的表单主题部分 -->
      <div class="center">
		  <div style="margin-left: -65%;margin-bottom: 10px;">促销>添加优惠券</div>
		  <div class="bck">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="优惠券类型:" prop="name">
            <el-select
              v-model="coupon_type"
              placeholder="请选择优惠券类型"
              style="width: 300px"
            >
              <el-option
                v-for="item in couponType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="优惠券名称:" prop="coupon_name">
            <el-input
              v-model="ruleForm.coupon_name"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="适用商户:" v-show="merchantShow">
            <el-select
              v-model="business_id"
              placeholder="请选择商户"
              @change="changeMerchant(business_id)"
              style="width: 300px"
            >
              <el-option
                v-for="item in businessList"
                :key="item.business_id"
                :label="item.business_name"
                :value="item.business_id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="适用门店:" v-show="merchantShow">
            <el-select
              @change="changeStore()"
              v-model="storeid"
              placeholder="请选择门店"
              style="width: 300px"
            >
              <el-option
                v-for="item in storeList"
                :key="item.s_name"
                :label="item.s_name"
                :value="item.storeid"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="总发行量:"
            prop="circulation"
            placeholder="只能输入正整数"
          >
            <el-input
              style="width: 300px"
              v-model="ruleForm.circulation"
              placeholder="只能输入正整数"
            ></el-input>
          </el-form-item>

          <el-form-item label="面额:" prop="discount_price">
            <el-input v-model="ruleForm.discount_price" style="width: 300px"></el-input>
          </el-form-item>

          <el-form-item label="每人限领:">
            <!-- <el-input v-model="ruleForm.name" style="width: 300px"></el-input> -->
            1张
          </el-form-item>

          <el-form-item label="使用门槛" prop="use_limits">
            <el-radio-group
              v-model="ruleForm.use_limits"
              @change="useLimits(ruleForm.use_limits)"
            >
              <el-radio label="1">无限制</el-radio>
              <el-radio label="2">满<el-input
                  @focus="limits()"
                  v-model="limits_price"
                  style="width: 60px"
                ></el-input>
                元可用</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="有效期" prop="type_time">
            <el-radio-group v-model="ruleForm.type_time" @change="typeTime(ruleForm.type_time)">
              <el-radio
                label="2"
                style="
                  display: block;
                  line-height: 60px;
                  white-space: normal;
                  margin-right: 0;
                "
              >
                日期范围

                <el-date-picker
                  style="width: 150px"
                  v-model="start_time"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择开始日期"
                  @change="startTime(start_time)"
                >
                </el-date-picker>
                <el-date-picker
                  style="width: 150px"
                  v-model="end_time"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择结束日期"
                  @change="endTime(end_time)"
                >
                </el-date-picker>
              </el-radio>

              <el-radio label="1"
                >固定天数
                <el-input
                  @focus="effective()"
                  v-model="effective_days"
                  style="width: 305px"
                ></el-input>
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="可使用商品" prop="goods_type">
            <el-radio-group v-model="ruleForm.goods_type" @change="goodsType">
              <el-radio label="1">全场通用</el-radio>
              <el-radio label="2">指定商品</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="可使用商品"
            v-show="goodsListShow"
          >
            <div class="popContainer">
              <div class="transition-box">
                <div style="margin-top: 5px">
                  <el-input
                    v-model="searchIpt"
                    placeholder="商品名称/商品货号"
                    style="width: 200px"
                    size="small"
                  ></el-input>
                  <el-button
                    @click="searchBtn"
                    style="padding: 3px 10px"
                    type="text"
                    >搜索按钮</el-button
                  >
                  当前已选择{{ selectGoods }}件商品
                  <!-- <el-button @click="addGoodsList" style="float: right"
                    >确认添加</el-button
                  > -->
                </div>

                <div class="table" style="margin-top: 2px; text-align: center">
                  <el-table
                    height="380px"
                    ref="multipleTable"
                    :data="addTableData"
                    tooltip-effect="dark"
                    @selection-change="handleSelectionChange"
                  >
                    <el-table-column
                      label="商品名称"
                      width="200"
                      align="center"
                    >
                      <template slot-scope="scope" align="center">{{
                        scope.row.gname
                      }}</template>
                    </el-table-column>
                    <el-table-column label="货号" width="180" align="center">
                      <template slot-scope="scope">{{
                        scope.row.gcode
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="original_price"
                      label="价格"
                      show-overflow-tooltip
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      type="selection"
                      lable="操作"
                      width="55"
                      align="center"
                    >
                    </el-table-column>
                  </el-table>
                  <el-pagination
                    style="margin-top: 15px"
                    :page-sizes="[10, 20, 30, 40]"
                    layout="total,  prev, pager, next, jumper"
                    :total="goodsListTotal"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button type="danger" @click="submitForm('ruleForm')"
              >立即创建</el-button
            >
            <el-button type="danger" plain @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
		</div>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";
export default {
  data() {
    return {
      value1: "",
      value2: "",
      top1: "7-2",
      top2: ["7"],
      flag: 1,
      merchantShow: true, //商户和门店的显示和隐藏
      coupon_type: "", //优惠券类型
      input: "",
      businessList: [{}], //商户列表
      business_id: "", //选中的商户ID
      storeList: [{}], //门店列表
      storeid: "", //选中的门店ID

      
      limits_price: "", //满多少可用
     
      start_time: "", //开始时间
      end_time: "", //结束时间
      effective_days: "", //有效天数
      goods_type: "", //可使用产品
      goodsListShow: false, //商品列表的显示隐藏
      addTableData: [], //添加优惠券的商品列表
      searchIpt: "", //搜索商品的输入框
      goodsListTotal: 10, //商品列表总条数
      selectGoods: 0, //选中的商品条数
      dataSelections: [], //选中的表格数据
      couponType: [
        {
          value: "1",
          label: "注册赠券",
        },
        {
          value: "2",
          label: "购物赠券",
        },
        {
          value: "3",
          label: "全场赠券",
        },
        {
          value: "4",
          label: "会员赠券",
        },
      ],
      currentPage: 1, //添加商品框列表的页码
      aglTotal: 100, //总条数
      ruleForm: {
        coupon_name: "", //优惠券名称
        circulation: "", //发型总量数据
        discount_price: "", //面额
        use_limits: "", //使用门槛 1是无限制，2是满多少可用
         type_time: "", //时间类型，1是天数，2是日期
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      rules: {
        coupon_name: [
          { required: true, message: "请输入优惠券名称", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        circulation: [
          { required: true, message: "请输入发行量", trigger: "blur" },
        ],
        discount_price: [
          { required: true, message: "请输入面额", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择优惠券类型", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        use_limits: [
          {
            required: true,
            message: "请选择使用门槛",
            trigger: "change",
          },
        ],
        goods_type: [
          {
            required: true,
            message: "请选择使用商品",
            trigger: "change",
          },
        ],
        type_time: [
          {
            required: true,
            message: "请选择有效期",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
    };
  },
  methods: {
    //刷新页面按钮
    refresh() {
      location.reload();
    },

    //选中商户查询门店
    changeMerchant(business_id) {
      console.log(business_id);
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: business_id,
        })
        .then((res) => {
          console.log(res);
          this.storeList = res.data;
        });
    },
    changeStore(){},

    //选择使用门槛
    useLimits(val) {
      console.log("使用门槛", val);
      return false
      if (val == 1) {
        this.limits_price = "";
      }
    },

    //输入满多少元事获取到单选框
    limits() {
      console.log("获取焦点");

      this.use_limits = "2";
    },

    

    //选择时间类型
    typeTime(val) {
      console.log("时间类型", val);
      // this.type_time = val;
      if (val == 2) {
        this.effective_days = "";
      } else if (val == 1) {
        (this.start_time = ""), (this.end_time = "");
      }
    },

    //选择开始时间
    startTime(start_time) {
      console.log("选择开始时间", start_time);
      if (start_time != "" || end_time != "") {
        this.type_time = "2";
      }
    },

    //选择结束时间
    endTime(end_time) {
      console.log("选择结束时间", end_time);
      if (start_time != "" || end_time != "") {
        this.type_time = "2";
      }
    },

    //固定天数输入框获取光标
    effective() {
      console.log(111111);
      this.type_time = "1";
      (this.start_time = ""), (this.end_time = "");
    },

    //选择可使用商品类型
    goodsType() {
      console.log(this.goods_type);
      console.log(this.business_id, this.storeid);
      if (this.goods_type == 2) {
        this.$request
          .couponShop({
            token: this.$storage.getLocal("token"), //登陆进来的tooken
            admin_type: this.$storage.getLocal("type"), //身份
            goods_type: this.goods_type,
            search: "", //搜索值
            business_id: this.business_id, //商户门店ID，超管传 其余传空
            storeid: this.storeid,
            page: this.currentPag,
            limit: 10,
            goods_ids: this.goods_ids,
          })
          .then((res) => {
            this.goodsListShow = true;
            console.log("所有活动商品数据", res.data);
            this.addTableData = res.data.data;
            this.goodsListTotal = res.data.count; //搜索完成列表的数据条数
          });
      } else if (this.goods_type == 1) {
        this.goodsListShow = false;
      }
    },

    //表格多选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    //选中的表格数据
    handleSelectionChange(val) {
      console.log(val);
      console.log(val.length);
      this.dataSelections = val;

      this.selectGoods = val.length;
    },

    //搜索按钮
    searchBtn() {
      this.$request
        .couponShop({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          goods_type: this.goods_type,
          search: "", //搜索值
          business_id: this.business_id, //商户门店ID，超管传 其余传空
          store_id: this.storeid,
          page: this.currentPag,
          limit: 10,
          goods_ids: this.goods_ids,
        })
        .then((res) => {
          this.goodsListShow = true;
          console.log("所有活动商品数据", res.data);
          this.addTableData = res.data.data;
          this.goodsListTotal = res.data.count; //搜索完成列表的数据条数
        });
    },

    // 页面跳转
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPag = `${val}`;
      console.log(this.currentPag);
      this.$request
        .couponShop({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          goods_type: this.goods_type,
          search: "", //搜索值
          business_id: this.business_id, //商户门店ID，超管传 其余传空
          store_id: this.storeid,
          page: this.currentPag,
          limit: 10,
          goods_ids: this.goods_ids,
        })
        .then((res) => {
          this.goodsListShow = true;
          console.log("所有活动商品数据", res.data);
          this.addTableData = res.data.data;
          this.goodsListTotal = res.data.count; //搜索完成列表的数据条数
        });
    },

    // 提交按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('商品数据');
          console.log(
      /*       coupon_name: "", //优惠券名称
        circulation: "", //发型总量数据
        discount_price: "", //面额
        use_limits: "", //使用门槛 1是无限制，2是满多少可用
         type_time: "", //时间类型，1是天数，2是日期 */
            this.coupon_type,
            this.ruleForm.coupon_name,
            this.business_id,
            this.storeid,
            this.ruleForm.circulation,
            this.ruleForm.discount_price,
            this.ruleForm.use_limits,
            this.ruleForm.limits_price,
            this.ruleForm.type_time,
            this.start_time,
            this.end_time,
            this.effective_days
            // this.limits_price,
          );
          // return false

          let val = this.dataSelections;
          for (var i = 0; i < val.length; i++) {
            // val[i].active_id = this.active_id;
            val[i].type = 1;
            delete val[i].gname;
            delete val[i].gcode;
            delete val[i].original_price;
            delete val[i].type;
          }
          this.dataSelections = JSON.stringify(val);
          console.log(11111111111, this.dataSelections);
          // return false;
          this.$request
            .addCouponInfo({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              goods_type: this.goods_type,
              type: this.coupon_type,
              coupon_name: this.ruleForm.coupon_name,
              business_id: this.business_id,
              store_id: this.storeid,
              number: this.ruleForm.circulation,
              discount_price: this.ruleForm.discount_price,
              use_limits: this.ruleForm.use_limits,
              limits_price: this.limits_price,
              type_time: this.ruleForm.type_time,
              start_time: this.start_time,
              end_time: this.end_time,
              effective_days: this.effective_days,
              goods_type: this.goods_type,
              goods_data: this.dataSelections,
              remark: "备注",
            })
            .then((res) => {
              console.log("添加成功", res);
              return false;
              this.$router.push({ path: "couponList" });
              // this.addTableData = res.data.data;
              // this.goodsListTotal = res.data.count; //搜索完成列表的数据条数
            });
        } else {
          console.log('不全');
          // const h = this.$createElement;
          //       this.$notify({
          //         title: "消息提示",
          //         message: h(
          //           "i",
          //           { style: "color: red;font-size:28px" },
          //           "修改成功"
          //         ),
          //       });
        }
      });
    },

    // 重置按钮
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },

  created() {
    //商户列表接口
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.businessList = res.data;
    });

    // //优惠券类型接口couponType
    // this.$request.couponType({}).then((res) => {
    //   console.log(res);
    //   // this.businessList = res.data;
    // });
    let admin_type = this.$storage.getLocal("type");
    if (admin_type == 2 || admin_type == 3) {
      this.merchantShow = false;
    }
  },
  components: {
    frame,
  },
};
</script>

<style  scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 40px;
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.center {
/*  width: 95%;
  margin-left: 10%; */
  /* height: 500px; */
  /* border: 1px solid red; */
}
.center
	{
	 background-color: rgb(245,247,249);
	 padding-top: 10px;
	 padding-bottom: 25px;
	}
	
	.bck
	{
		width: 85%;
		background-color: white;
		/* margin-top: 20px; */
		/* border: 1px solid red; */
		margin-left: 14%;
		/* margin-top: 20px; */
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 20%;
		padding-right: 200px;
		/* line-height: 20px; */
	}
</style>